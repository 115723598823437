<div class="d-flex flex-wrap align-items-center">
  <div class="mr-auto">
    <p class="text-uppercase text-dark font-weight-bold mb-1">
      Refunds
    </p>
    <p class="text-gray-5 mb-0">
      Averache Weekly Reunds
    </p>
  </div>
  <p class="text-danger font-weight-bold font-size-24 mb-0">
    -$8,474
  </p>
</div>
