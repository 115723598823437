<div *ngIf=myService()>
  <div class="textDivider mb-2">
    <h4 class="textDividerContent font-size-24 font-weight-bold">
      Onboarded Users
    </h4>
  </div>
  <nz-table #basicTable [nzShowPagination]="false" [nzData]="listOfAllData"
    (nzCurrentPageDataChange)="currentPageDataChange($event)" class="table mb-4">
    <thead>
      <tr>
        <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)"></th>
        <th class="text-uppercase">Username</th>
        <th class="text-uppercase">Description</th>
        <th class="text-uppercase text-right">Start Date</th>
        <th class="text-uppercase">End Date</th>
        <th class="text-uppercase">Active</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of businessServiceOnboardings">
        <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.b2bid]"
          (nzCheckedChange)="refreshStatus()"></td>
        <td>
          {{data.fkUser}}
        </td>
        <td>
          {{ (data.description.length > 40) ? ((data.description).slice(0,40) + ' ...') : data.description}}
        </td>
        <td class="text-right font-weight-bold">
          {{data.startDate.slice(0,10)}}
        </td>
        <td>
          {{data.endDate}}
        </td>
        <td>
          {{data.active}}
        </td>
  
        <td>
          <button type="button" *ngIf="!data.active" class="btn btn-outline-success mr-2 mb-2" (click)="togleOnboardedServiceAccept(data.b2bID)">Grant Access &nbsp; <i class="fe fe-check-square"></i></button>
          <button type="button" *ngIf="data.active" class="btn btn-outline-warning mr-2 mb-2" (click)="togleOnboardedServiceReject(data.b2bID)">Revoke Access &nbsp; <i class="fe fe-slash"></i></button>
        </td>
        <td>  
          <button type="button" class="btn btn-outline-warning mr-2 mb-2" (click)="removeServiceUser(data.fkService,data.fkUser)" >Remove User &nbsp; <i class="fe fe-trash-2"></i></button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>