import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import { Observable } from 'rxjs'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { NzNotificationService } from 'ng-zorro-antd'
import { truncate } from 'fs'

@Component({
  selector: 'kit-list-2',
  templateUrl: './2.component.html',
  styleUrls: ['./2.component.scss'],
})
export class CuiList2Component implements OnInit {
  backend : string;
  notifications: any;

  constructor(private route: ActivatedRoute,private http: HttpClient,private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }
  ngOnInit() {
    this.notificationsList();
  }


  onboardingType(notification: any): boolean{
    if(notification.type === 'NEW_ONBOARDING' && notification.read==false){
      return true;
    }
    else{
      return false;
    }
  }

  notificationsList():void {
    this.notificationsListRequest().subscribe(
      {
        next : data => {
          this.notifications=data
        },
        error: error => {
        }
    })
  }

  notificationsListRequest(): Observable <any> {
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}
    return this.http.get(this.backend+'/api/notifications/list_all_notifications',params)
  }


  markAsRead(notificationId:string): void{
    this.markAsReadRequest(notificationId).subscribe(
      {
        next : data => {
          this.ngOnInit()
        },
        error: error => {
        }
    })
  }

  markAsReadRequest(notificationId:string): Observable <any> {
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    return this.http.put<any>(this.backend+'/api/notifications/mark_as_read?notificationId='+notificationId,{},{headers})
  }

  delete(notificationId:string): void{
    this.deleteRequest(notificationId).subscribe(
      {
        next : data => {
          this.ngOnInit()
        },
        error: error => {
        }
    })
  }

  deleteRequest(notificationId:string): Observable <any> {
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    return this.http.delete<any>(this.backend+'/api/notifications/delete_notification?notificationId='+notificationId,{headers})
  }
}
