<div class="card overflow-hidden">
  <div *ngIf="isActive" class="new">
    Active
  </div>
  <div class="card-body">
    <a
      class="favorite"
      [ngClass]="[isFavorite ? 'text-dark' : 'text-gray-3']"
      [routerLink]=""
      (click)="setFavorite()"
    >
      <i class="fe fe-heart font-size-21"></i>
    </a>
    <div class="image border-bottom height-150 mb-3">
      <img class="img-fluid" [src]="image" [alt]="name" />
    </div>
    <div>
      <a [routerLink]="['/service/service-details',id]" class="text-blue font-size-18">
        {{name}}
      </a>
    </div>
    <div class="font-size-20 font-weight-bold mb-2">
      {{platform}}{{'   '}}{{ontology}}{{' '}}{{ontologyVersion}}{{'   '}}{{type}}
    </div>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>