<div class="mb-3">
  <div class="text-uppercase mb-3">Users by location</div>
  <div class="table-responsive">
    <table class="table table-borderless text-gray-6 mb-0">
      <tbody>
      <tr>
        <td class="pl-0 font-weight-bold">San Francisco, California</td>
        <td class="text-right text-muted">645,215</td>
      </tr>
      <tr>
        <td class="pl-0 font-weight-bold">Los Angeles, California</td>
        <td class="text-right text-muted">128,125</td>
      </tr>
      <tr>
        <td class="pl-0 font-weight-bold">Las Vegas, Nevada</td>
        <td class="text-right text-muted">97,125</td>
      </tr>
      <tr>
        <td class="pl-0 font-weight-bold">Austin, Texas</td>
        <td class="text-right text-muted">85,621</td>
      </tr>
      <tr>
        <td class="pl-0 font-weight-bold">Arlington, Nebraska</td>
        <td class="text-right text-muted">18,363</td>
      </tr>
      <tr>
        <td class="pl-0 font-weight-bold">San Francisco, California</td>
        <td class="text-right text-muted">1,367</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
