import { Component, OnInit, Input } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd'

declare var require: any
const data: any = require('./data.json')

@Component({
  selector: 'kit-table-7',
  templateUrl: './7.component.html',
  styleUrls: ['./7.component.scss'],
})
export class CuiTable7Component implements OnInit {
  isAllDisplayDataChecked = false
  isIndeterminate = false
  listOfDisplayData = data
  listOfAllData = data
  mapOfCheckedId = { 1: true, 2: true, 3: true }

  backend : string;
  service:any;
  businessServiceOnboardings:any;

  @Input()
  id: any;

  currentPageDataChange($event): void {
    this.listOfDisplayData = $event
    this.refreshStatus()
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter(item => item.active)
      .every(item => this.mapOfCheckedId[item.b2bid])
    this.isIndeterminate =
      this.listOfDisplayData
        .filter(item => item.active)
        .some(item => this.mapOfCheckedId[item.b2bid]) && !this.isAllDisplayDataChecked
  }

  checkAll(value): void {
    this.listOfDisplayData
      .filter(item => item.active)
      .forEach(item => (this.mapOfCheckedId[item.b2bid] = value))
    this.refreshStatus()
  }

  constructor(private http: HttpClient,private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }

  ngOnInit() {
    this.loadServiceDetails(this.id)
    .subscribe({
      next : data => {
        this.service=data
        this.businessServiceOnboardings=this.service.businessServiceOnboardings
      },
      error: error => {
        this.notification.warning('Error','It was implossible to collect service details.');
        console.error('There was an error!', error);
      }
    });
  }

  loadServiceDetails(id: number) : Observable <any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/services/services/{serviceId}/details?serviceId='+id;
    return this.http.post<any>(baseURL,{},{headers})
  }

  togleOnboardedServiceAccept(b2bid:number){
    this.togleOnboardedService(b2bid,true).subscribe(
      {
        next : data => {
            this.ngOnInit()
        },
        error: error => {
            console.error('There was an error!', error);
          }
        }
    )
  }

  togleOnboardedServiceReject(b2bid:number){
    this.togleOnboardedService(b2bid,false).subscribe(
      {
        next : data => {
            this.ngOnInit()
        },
        error: error => {
            console.error('There was an error!', error);
          }
        }
    )
  }

  togleOnboardedService(b2bid:number, status:any): Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/services/service/changeOnboarding';
    const body = {
        "b2bID": b2bid,
        "status": status
    }
    return this.http.post<any>(baseURL,body,{headers})
  }


}
