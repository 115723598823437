<div>
  <div class="d-flex flex-nowrap align-items-center pb-4">
    <div class="mr-auto">
      <div class="text-uppercase font-weight-bold font-size-24 text-dark">US 4658-1678-7528</div>
      <div class="font-size-18">$122,059.52</div>
    </div>
    <div class="ml-1">
      <i class="fe fe-star font-size-40"></i>
    </div>
  </div>
  <div class="border-top text-center text-gray-4 pt-3">
    This month loss: $3,647.04
  </div>
</div>
