import { Component, Input, OnInit } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import { select, Store } from '@ngrx/store'
import store from 'store'
import { Observable } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd'

@Component({
  selector: 'kit-general-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class CuiGeneralkpiComponent implements OnInit {
  @Input() kpi_id:number
  @Input() image: string
  @Input() name: string
  kpi: any;
  backend: any;
  ratio:Number;
  kpiFirstReading;any;
  isFavorite:boolean;
  hasData:boolean;

  favoriteKPIS : any;

  constructor(private http: HttpClient,private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  
    this.isFavorite=false; 
    this.ratio=0;
    this.hasData=false;
  }

  ngOnInit() {
    this.favoriteKPIS=store.get('prefered_kpis');

    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    this.http.get(this.backend+'/api/kpi/get?kpi_id='+this.kpi_id,params).subscribe((data: any) => {  
      this.kpi=data; 
      this.processImageLinks(); 
      this.isFavorite=this.favoriteKPIS.some(x => x === this.kpi_id)
      if(data.kpiHistoric.lenght!=0){
        this.kpiFirstReading=data.kpiHistoric[0].value;
        this.ratio = (parseFloat(data.kpiHistoric[0].value)/parseFloat(data.goal))*100
        this.hasData=true;
      }
    });
  }


  setFavorite() {
    if(this.isFavorite==false){
      this.addKPItoFavoriteListRequest().subscribe(
        {
          next : data => {
            this.isFavorite = !this.isFavorite
          },
          error: error => {
            this.notification.warning('KPI Error','It was not possible to set this KPI as favorite');
          }
        }
      ) 
    }
    if(this.isFavorite==true){
      this.removeKPItoFavoriteListRequest().subscribe(
        {
          next : data => {
            this.isFavorite = !this.isFavorite
          },
          error: error => {
            this.notification.warning('KPI Error','It was not possible to remove this KPI from favorite');
          }
        }
      ) 
    }

  }

  addKPItoFavoriteListRequest() : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/kpi/set_as_prefered?kpi_id='+this.kpi_id;
    return this.http.post<any>(baseURL,{},{headers})
  }
  
  removeKPItoFavoriteListRequest() : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
                                    .set('Content-Type', 'application/json')
                                    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/kpi/unset_as_prefered?kpi_id='+this.kpi_id;
    return this.http.post<any>(baseURL,{},{headers})
  }

  processImageLinks() : void {
      // merge objects into one with multiple props
      this.kpi = Object.assign(this.kpi, {
        image : "assets/images/partners/"+this.kpi.provider+".png",
      });
  }

}
