<div class="d-flex flex-wrap align-items-center">
  <div class="mr-auto">
    <p class="text-uppercase text-dark font-weight-bold mb-1">
      Orders
    </p>
    <p class="text-gray-5 mb-0">
      All Time Orders
    </p>
  </div>
  <p class="text-primary font-weight-bold font-size-24 mb-0">
    1,800
  </p>
</div>
