<div>
  <!--
  <ul class="list list-unstyled" style="display:inline-block;">
    <li *ngFor="let level of certificationLevels; index as idx" class="item">
      <div class="itemMeta" *ngIf="selectedKnowledgeBase(idx)">
        <div class="donut info md"></div>
      </div>
      <div class="itemMeta" *ngIf="!selectedKnowledgeBase(idx)">
        <div class="donut md"></div>
      </div>
        <div class="mr-3">
          <div>{{level}}</div>
        </div>
    </li>
  </ul>
-->

<form nz-form [formGroup]="validateForm1" (ngSubmit)="submitForm1()">
  <nz-form-item>
    <nz-form-label [nzSm]="4" [nzXs]="4" class="text-left"></nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="10">
      <nz-slider [nzDots]=true [nzMax]=76 [nzDisabled]=false [nzTooltipVisible]="'never'" formControlName="budget" [nzMarks]="marks"></nz-slider>
    </nz-form-control>
  </nz-form-item>
</form>
</div>


