import { Component, Input, OnChanges } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store, StoreFeatureModule } from '@ngrx/store'
import { Observable } from 'rxjs'
import { FADE_CLASS_NAME_MAP, NzNotificationService } from 'ng-zorro-antd'

@Component({
  selector: 'kit-general-docker-container',
  templateUrl: './docker-container.component.html',
  styleUrls: ['./docker-container.component.scss'],
})
export class CuiGeneralDockerContainerComponent implements OnChanges {
  @Input() id:number
  @Input() image: string
  @Input() name: string
  @Input() ontology: string
  @Input() ontologyVersion: string
  @Input() type: string
  @Input() provider: string
  @Input() platform: string
  @Input() containerID: any;
  @Input() isActive:boolean;
  @Input() isRunning:boolean;

  backend : string;


  constructor(private fb: FormBuilder,private http: HttpClient,private router: Router, private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }

  ngOnChanges() {
  }

  ngOnInit() { 
    
   }



   startContainer(dockerId:number){
    this.startContainerRequest(dockerId).subscribe(
      {
        next: data => {
          this.notification.success("Container Started","The Docker container for the service was started.")
          this.router.navigateByUrl("/service/service-docker-controller/"+this.id)
        },
        error: error => {
          if(error.status === 401){
            this.notification.warning('Container failed to start','There was an error while attempting to start the container.');
          }
        }
      }
    )
  }

  startContainerRequest(dockerId: number) : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${accessToken}`)
    .set('Content-Type', 'application/json')
    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/dockers/start_container?dockerId='+dockerId;
    return this.http.get<any>(baseURL,{headers})
  }


  stopContainer(dockerId:number){
    this.stopContainerRequest(dockerId).subscribe(
      {
        next: data => {
          this.notification.success("Container Stopped","The Docker container for the service was stopped.")
          this.router.navigateByUrl("/service/service-docker-controller/"+this.id)
        },
        error: error => {
          if(error.status === 401){
            this.notification.warning('Container failed to stop','There was an error while attempting to stop the container.');
          }
        }
      }
    )
  }

  stopContainerRequest(dockerId: number) : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${accessToken}`)
    .set('Content-Type', 'application/json')
    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/dockers/stop_container?dockerId='+dockerId;
    return this.http.get<any>(baseURL,{headers})
  }

  removeContainer(dockerId:any){
    this.removeContainerRequest(dockerId).subscribe(
      {
        next: data => {
          this.notification.success("Container Removed","The Docker container was removec.")
          this.ngOnInit()
        },
        error: error => {
            this.notification.warning('Container Removal failed','There was an error while attempting to remove the container.');
        }
      }
    )
  }

  removeContainerRequest(dockerID: number) : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${accessToken}`)
    .set('Content-Type', 'application/json')
    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/dockers/remove_container?dockerId='+dockerID;
    return this.http.get<any>(baseURL,{headers})
  }

  containerIsStopped(){
    if(!this.isRunning){
      return true;
    }
    else{
      return false;
    }
  } 
}
