<div>
  <div class="card-body">
    <div class="text-dark font-size-18 font-weight-bold mb-1">Income Progress</div>
    <div class="text-gray-6 mb-2">Revenue by location and date</div>
    <div class="font-weight-bold font-size-36 text-dark mb-2">$390,012.01</div>
    <div class="d-flex align-items-center">
      <div class="bg-gray-4 text-white mr-3 progressIcon">
        <i class="fe fe-menu font-size-18"></i>
      </div>
      <div class="flex-grow-1">
        <div class="text-dark font-size-18 font-weight-bold text-nowrap mb-2">
          78% from $500,000.00
        </div>
        <div class="progress">
          <div class="progress-bar bg-success" style="width: 70%"></div>
        </div>
      </div>
    </div>
  </div>
  <x-chartist [data]="chartData" [type]="'Line'" [options]="chartOptions" class="height-200 chart ct-hidden-points">
  </x-chartist>
</div>