<div>
  <div class="textDivider mb-2">
    <h4 class="textDividerContent font-size-24 font-weight-bold">
      Certification Logs
    </h4>
  </div>
  <nz-table #basicTable [nzShowPagination]="false" [nzData]="listOfAllData"
    (nzCurrentPageDataChange)="currentPageDataChange($event)" class="table mb-4" style="word-wrap:break-word;">
    <thead>
      <tr>
        <th class="text-uppercase">Log ID</th>
        <th class="text-uppercase">Origin</th>
        <th class="text-uppercase">Operation</th>
        <th class="text-uppercase">Description</th>
        <th class="text-uppercase">Reply</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of txn.certificationLogs">
        <td>{{data.logID}}</td>
        <td><span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">{{data.origin}}</span></td>
        <td><span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">{{data.operation}}</span></td>
        <td>{{data.description}}</td>
        <td><span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">HTTP {{data.statusCode}}</span></td>
      </tr>
    </tbody>
  </nz-table>
</div>