import { Component } from '@angular/core'

@Component({
  selector: 'cui-topbar-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class TopbarActionsComponent {
  badgeCount: number = 1

  badgeCountIncrease() {
    this.badgeCount = 0

  }

}
