<div>
  <div class="text-dark font-size-18 font-weight-bold mb-1">Profit Change</div>
  <div class="text-gray-6 mb-2">Revenue by location and date</div>
  <div class="d-flex flex-wrap align-items-center">
    <div class="mr-3 mt-3 mb-3 position-relative">
      <div style="width: 140px;">
        <chart [type]="'doughnut'" [data]="chartData" [options]="options" ></chart>
      </div>
      <div class="tooltip text-center text-gray-5 font-size-28" #tooltip>
        <div class="font-size-14 font-weight-bold text-dark" #tooltipLabel></div>
        <div class="font-size-14 text-dark" #tooltipValue></div>
      </div>
    </div>
    <div #legend></div>
  </div>
</div>
