import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-17v2',
  templateUrl: './17v2.component.html',
  styleUrls: ['./17v2.component.scss'],
})
export class CuiGeneral17v2Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
