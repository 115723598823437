import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NzNotificationService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import * as Reducers from 'src/app/store/reducers'
import store from 'store'

@Component({
  selector: 'kit-general-13-interconnect',
  templateUrl: './13interconnect.component.html',
  styleUrls: ['./13interconnect.component.scss'],
})
export class CuiGeneral13InterconnectComponent implements OnInit {
  
  backend:any;
  users:any;
  
  constructor(private fb: FormBuilder, private http: HttpClient,private store: Store<any>, private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
    
  }
  ngOnInit() {
    this.getKPIRequest().subscribe(
      {
        next : data => {
          this.users = data.kpiHistoric[0].value;
        },
        error: error => {
          if(error.status === 401){
            this.notification.warning('Warning','It was impossible to collect KPI information.');
          }
        }
      }
    ) 
  }


  getKPIRequest() : Observable<any>{
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}
    const baseURL = this.backend+'/api/kpi/get?kpi_id=1';
    return this.http.get<any>(baseURL,params)
  }

}
