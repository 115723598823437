export default {
  'topBar.issuesHistory': 'Histoire des problèmes',
  'topBar.projectManagement': 'Gestion de projet',
  'topBar.typeToSearch': 'Chercher...',
  'topBar.findPages': 'Trouver des pages...',
  'topBar.actions': 'Actes',
  'topBar.status': 'Statut',
  'topBar.profileMenu.hello': 'Bonjour',
  'topBar.profileMenu.billingPlan': 'Plan de facturation',
  'topBar.profileMenu.role': 'Rôle',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Téléphone',
  'topBar.profileMenu.editProfile': 'Editer le profil',
  'topBar.profileMenu.logout': 'Connectez - Out',
}
