import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/cleanui/system/Guard/auth.guard'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/home',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'service',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/service/service.module').then(m => m.ServiceModule),
      },
      {
        path: 'ke',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/knowledge-explorer/knowlege-explorer.module').then(m => m.KnowledgeExplorerModule),
      },
      {
        path: 'kpi',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/KPIS/kpis.module').then(m => m.KpisExplorerModule),
      },
      {
        path: 'cards',
        canActivate: [AuthGuard],
        loadChildren: () => import('src/app/pages/cards/cards.module').then(m => m.CardsModule),
      },
      {
        path: 'advanced',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/advanced/advanced.module').then(m => m.AdvancedModule),
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
