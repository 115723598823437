<div>
  <div class="textDivider mb-2">
    <h4 class="textDividerContent font-size-24 font-weight-bold">
      Docker Container Images
    </h4>
  </div>
  <nz-table #basicTable [nzShowPagination]="false" [nzData]="dockerImages"
    (nzCurrentPageDataChange)="currentPageDataChange($event)" class="table mb-4">
    <thead>
      <tr>
        <th class="text-uppercase">Image ID</th>
        <th class="text-uppercase">Container ID</th>
        <th class="text-uppercase">Upload Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let image of dockerImages">
        <td>
          {{image.imageID}}
        </td>
        <td>
          {{image.containerID}}
        </td>
        <td>
          {{image.registerDate}}
        </td>
        <td>
          <button type="button" *ngIf="!image.active" class="btn btn-outline-success mr-2 mb-2" (click)="startImage(image.containerID)">START</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>