import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-21v1',
  templateUrl: './21v1.component.html',
})
export class CuiList21v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
