import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-27v1',
  templateUrl: './27v1.component.html',
  styleUrls: ['./27v1.component.scss'],
})
export class CuiGeneral27v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
