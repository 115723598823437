import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-23',
  templateUrl: './23.component.html',
})
export class CuiGeneral23Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
