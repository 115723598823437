import { Component, OnInit } from '@angular/core'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import { HttpClient } from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store } from '@ngrx/store'



declare var require: any
const data: any = require('./data.json')

@Component({
  selector: 'kit-chart-11',
  templateUrl: './11.component.html',
  styleUrls: ['./11.component.scss'],
})
export class CuiChart11Component implements OnInit {
  backend : string;
  number_services: any;

  chartData = data
  chartOptions: any = {}
  constructor(private http: HttpClient,private store: Store<any>) {
    this.chartOptions = {
      options: {
        axisX: {
          showLabel: false,
          showGrid: false,
          offset: 0,
        },
        axisY: {
          showLabel: false,
          showGrid: false,
          offset: 0,
        },
        showArea: true,
        high: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        fullWidth: true,
        height: '110px',
        showPoint: true,
        plugins: [
          ChartistTooltip({
            anchorToPoint: false,
            appendToBody: true,
            seriesName: false,
          }),
        ],
      },
      low: 20,
      type: 'Line',
    }
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })

  }
  ngOnInit():void {
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    this.http.get(this.backend+'/api/services/service_list',params).subscribe((data: any) => {  
      this.number_services=data.length;
    });

  }
}
