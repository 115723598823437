import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import { select, Store, StoreFeatureModule } from '@ngrx/store'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'




@Component({
  selector: 'kit-list-certificationLevels',
  templateUrl: './certificationLevels.component.html',
  styleUrls: ['./certificationLevels.component.scss'],
})
export class CuiListCertificationLevelsComponent implements OnInit {
  @Input() txns:any; 
  certificationLevels = ['No Certification','Level 1', 'Level 2', 'Level 3'];
  marks = {
    0: 'No Certification',
    25: 'Level 1',
    50: 'Level 2',
    75: 'Level 3',
  }
  

  validateForm1: FormGroup
  submitForm1(): void {
    for (const i in this.validateForm1.controls) {
      if (this.validateForm1.controls.hasOwnProperty(i)) {
        this.validateForm1.controls[i].markAsDirty()
        this.validateForm1.controls[i].updateValueAndValidity()
      }
    }
  }
  indexInFocus: number;
  @Output() KnowledgeBaseInFocus = new EventEmitter<number>();


  constructor(private notification: NzNotificationService,private store: Store<any>,private http: HttpClient,private fb: FormBuilder) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
    })
  }

  ngOnInit() {
    var set_level= 0;
    if(this.txns.level === "Level 1"){
      set_level = 25
    }
    if(this.txns.level === "Level 2"){
      set_level = 50
    }
    if(this.txns.level === "Level 3"){
      set_level = 75
    }
    this.validateForm1 = this.fb.group({
      fullName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      budget: [set_level],
    })
    this.indexInFocus=1
  }

  selectedKnowledgeBase(index:number){
    if(index == this.indexInFocus){
      return true;
    }
  }

}
