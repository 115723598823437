<div>
  <nz-tabset class="tabs" [nzSelectedIndex]="0">
    <nz-tab nzTitle="Notifications">
      <div class="text-gray-6">
        <ul class="list-unstyled">
          <li *ngFor="let notification of notifications" class="mb-4">

            <div *ngIf="!notification.read" class="head">
              <p class="title"><strong class="text-black">New</strong></p>
              <p>{{notification.message}}</p>
              <time class="time">{{notification.regDate.substring(0, 10)}}&nbsp;</time>
              
              <button type="button"  *ngIf=!notification.read class="btn btn-light float:right" (click)="markAsRead(notification.notificationId)"><i nz-icon nzType="check-circle" nzTheme="fill"></i></button>
            </div>
            <div *ngIf="notification.read" class="head">
              <p>{{notification.message}}</p>
              <time class="time">{{notification.regDate.substring(0, 10)}}</time>
              <button type="button"  *ngIf=notification.read class="btn btn-light" (click)="delete(notification.notificationId)"><i nz-icon nzType="delete" nzTheme="fill"></i></button>
            </div>  
            <button type="button"  *ngIf=onboardingType(notification) class="btn btn-light float:right" [routerLink]="['/service/service-business-onboardings',notification.serviceId]">Onboarded Users</button>
            
          </li>
        </ul>
      </div>
    </nz-tab>
  </nz-tabset>
</div>