import { Component, ViewChild, ElementRef } from '@angular/core'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { HttpClient } from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'cui-topbar-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class TopbarSearchComponent {
  @ViewChild('liveSearchInput') liveSearchInput: ElementRef

  showSearch: boolean = false
  searchText: string = ''
  backend:any;
  services:any;
  displayedServices:any;

  constructor(private http: HttpClient,private store: Store<any>) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
    document.addEventListener('keydown', this.handleKeyDown.bind(this), false)
  }

  ngOnInit() :void {
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    this.http.get(this.backend+'/api/services/service_list',params).subscribe((data: any) => {  
      this.services=data;
      this.processImageLinks();
    });
  }

  processImageLinks() : void {
    for (var i = 0; i < this.services.length; i++) {
      // merge objects into one with multiple props
      this.services[i] = Object.assign(this.services[i], {
        image : "assets/images/partners/"+this.services[i].platform+".png",
      });
    }
  }

  setVisible() {
    this.showSearch = true
    setTimeout(() => {
      this.liveSearchInput.nativeElement.focus()
    }, 100)
  }

  setHidden() {
    this.showSearch = false
  }

  handleKeyDown(event: any) {
    //§this.displayedServices=this.services;
    this.displayedServices = this.services.filter(service => service.name.toLowerCase().includes(this.searchText.toLowerCase()) || service.platform.toLowerCase().includes(this.searchText.toLowerCase()));
    if (this.showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        this.setHidden()
      }
    }
  }
}
