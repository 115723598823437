import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-1',
  templateUrl: './1.component.html',
  styleUrls: ['./1.component.scss'],
})
export class CuiList1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
