<div>
  <ul class="list-unstyled">
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold text-danger">
          2:28
          <br/>
          PM
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold text-blue">
          1:02
          <br/>
          AM
        </div>
        <div class="mr-3">
          <div>Account Activated</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold">
          2:28
          <br/>
          PM
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold">
          2:28
          <br/>
          PM
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold">
          2:28
          <br/>
          PM
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta font-weight-bold">
          2:28
          <br/>
          PM
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
  </ul>
</div>
