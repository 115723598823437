import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-14',
  templateUrl: './14.component.html',
})
export class CuiList14Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
