import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-13interconnect-knowledgeBases',
  templateUrl: './13interconnect-knowledgeBases.component.html',
  styleUrls: ['./13interconnect-knowledgeBases.component.scss'],
})
export class CuiGeneral13interconnectKnowledgeBasesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
