import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-21v2',
  templateUrl: './21v2.component.html',
})
export class CuiList21v2Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
