import { Component, OnInit, Input } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd'

declare var require: any
const data: any = require('./data.json')

@Component({
  selector: 'kit-certification-mismatches-table',
  templateUrl: './certification-mismatches-table.component.html',
  styleUrls: ['./certification-mismatches-table.component.scss'],
})
export class CertificationMismatchesComponent implements OnInit {
  isAllDisplayDataChecked = false
  isIndeterminate = false
  listOfDisplayData = data
  listOfAllData = data
  mapOfCheckedId = { 1: true, 2: true, 3: true }

  backend : string;
  reason = "Tag does not exist in the ontology description";

  @Input()
  txn: any;
  @Input()
  result:boolean;

  currentPageDataChange($event): void {
    this.listOfDisplayData = $event
    this.refreshStatus()
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter(item => item.active)
      .every(item => this.mapOfCheckedId[item.b2bid])
    this.isIndeterminate =
      this.listOfDisplayData
        .filter(item => item.active)
        .some(item => this.mapOfCheckedId[item.b2bid]) && !this.isAllDisplayDataChecked
  }

  checkAll(value): void {
    this.listOfDisplayData
      .filter(item => item.active)
      .forEach(item => (this.mapOfCheckedId[item.b2bid] = value))
    this.refreshStatus()
  }

  constructor(private http: HttpClient,private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }

  ngOnInit() {
  this.txn.certificationLogs = this.txn.certificationLogs.sort((a, b) => (a.logID > b.logID ? -1 : 1));
  }



}
