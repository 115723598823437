import { Component, OnInit } from '@angular/core'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { HttpClient } from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
declare var require: any



@Component({
  selector: 'kit-chart-onlineAdapters',
  templateUrl: './onlineAdapters.component.html',
  styleUrls: ['./onlineAdapters.component.scss'],
})
export class CuiChartOnlineAdaptersComponent implements OnInit {
  onlineAdapters:number;

  chartOptions: any = {}
  backend: any
  constructor(private http: HttpClient,private store: Store<any>) {
    this.chartOptions = {
      options: {
        axisX: {
          showLabel: false,
          showGrid: false,
          offset: 0,
        },
        axisY: {
          showLabel: false,
          showGrid: false,
          offset: 0,
        },
        showArea: true,
        high: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        fullWidth: true,
        height: '110px',
        showPoint: true,
      },
      low: 20,
      type: 'Line',
    }
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }
  ngOnInit() {
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    this.http.get(this.backend+'/api/adapter/adapter_list',params).subscribe((data: any) => { 
      const aux=data.filter(function (item){
        return item.active === true;
      });
      this.onlineAdapters=aux.length;
    });
  }


}
