<nz-layout>
  <nz-content>
    <cui-sidebar></cui-sidebar>
    <!--<cui-support-chat></cui-support-chat>-->
    <div class="authContainer" [ngClass]="{
        'cui__layout__squaredBorders': isSquaredBorders,
        'cui__layout__cardsShadow': isCardShadow,
        'cui__layout__borderless': isBorderless,
        'white': authPagesColor === 'white',
        'gray': authPagesColor === 'gray'

      }" [ngStyle]="{backgroundImage: authPagesColor === 'image' ? 'url(assets/images/interconnect-background.jpg)' : ''}">
      <div class="topbar" [ngClass]="{
        'topbarGray': isGrayTopbar
      }">
        <div class="logoContainer">
          <div class="logo">
            <img src="assets/images/logo.svg" class="mr-2" alt="Clean UI" />
            <div class="name">{{ logo }}</div>
            <div *ngIf="logo === 'Clean UI Pro'" class="descr">Service Store</div>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <span class="mr-2">Don't have an account?</span>
          <a routerLink="/auth/register" class="font-size-16 kit__utils__link">
            Sign up
          </a>
        </div>
      </div>
      <div class="containerInner">
        <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <div class="softcolour mt-auto pb-5 pt-5 ">
        <ul class="footerNav list-unstyled d-flex mb-0 flex-wrap justify-content-center">
          <li class="list-inline-item">
            <a class=softcolour  href="javascript: void(0);">Terms of Use</a>
          </li>
          <li class="active list-inline-item">
            <a class=softcolour  href="javascript: void(0);">Compliance</a>
          </li>
          <li class="list-inline-item">
            <a class=softcolour href="javascript: void(0);">Support</a>
          </li>
          <li class="list-inline-item">
            <a class=softcolour  href="javascript: void(0);">Contacts</a>
          </li>
        </ul>
        <div class="text-center softcolour">
          Copyright © 2022 Knowledge Engine
          <a class=softcolour  href="" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>