<div class="card border-0 bg-light">
  <div class="card-body">
    <div class="d-flex flex-wrap align-items-center">
      <i class="fe fe-server font-size-50 mr-3 text-gray-5"></i>
      <div class="text-dark">
        <div class="font-size-21 font-weight-bold">Databases</div>
        <div class="font-size-15">Total: 78,155</div>
      </div>
    </div>
  </div>
</div>