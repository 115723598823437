import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-16',
  templateUrl: './16.component.html',
  styleUrls: ['./16.component.scss'],
})
export class CuiList16Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
