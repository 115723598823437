<div>
  <ul class="list list-unstyled">
    <li *ngFor="let kb of knowledgeBases; index as idx" class="item">
      <a [routerLink]="['/ke/knowledge-explorer']" (click)="updateFocusKnowledgeBase(idx)" class="itemLink">
        <div class="itemMeta" *ngIf="selectedKnowledgeBase(idx)">
          <div class="donut warning md"></div>
        </div>
        <div class="itemMeta" *ngIf="!selectedKnowledgeBase(idx)">
          <div class="donut info md"></div>
        </div>
        <div class="mr-3">
          <div>{{kb.knowledgeBaseName}}</div>
          <div class="text-muted">{{kb.knowledgeBaseDescription}}</div>
        </div>
        <!--<div class="itemAction">
          <span></span>
          <span></span>
        </div>-->
      </a>
    </li>
  </ul>
</div>
