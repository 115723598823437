import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store } from '@ngrx/store'

@Injectable()
export class jwtAuthService {
  backend : string;

  constructor(private http: HttpClient,private store: Store<any>) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })  
  }

  login(email: string, password: string): Observable<any> {
    const headers = {'Content-Type':'application/json'};
    return this.http.post(this.backend+'/users/signin', { email, password }, {headers})
  }

  register(email: string, password: string, firstname: string, lastname:string, organization:string): Observable<any> {
    const headers = {'Content-Type':'application/json'};
    
    return this.http.post(this.backend+'/users/create', { email, password, firstname, lastname, organization},{headers})
  }

  currentAccount(): Observable<any> {
    const headers = {'Content-Type':'application/json'};
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    return this.http.get(this.backend+'/users/userinfo', params)
  }

  logout(): Observable<any> {
    return this.http.get('/api/auth/logout')
  }
}
 