import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store, StoreFeatureModule } from '@ngrx/store'
import { Observable } from 'rxjs'
import { timingSafeEqual } from 'crypto'

@Component({
  selector: 'kit-list-knowledgeBases',
  templateUrl: './knowledgeBases.component.html',
  styleUrls: ['./knowledgeBases.component.scss'],
})
export class CuiListknowledgeBasesComponent implements OnInit {
  backend : string;
  keBackend: string;
  knowledgeBases: string;
  indexInFocus: number;
  @Output() KnowledgeBaseInFocus = new EventEmitter<number>();


  constructor(private notification: NzNotificationService,private store: Store<any>,private http: HttpClient) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
      this.keBackend = state.KEBackend
    })
  }

  ngOnInit() {
    this.indexInFocus = store.get('knowledgeBaseinFocus');
    //this.getKnowledgeBases();
    this.knowledgeBases=store.get('knowledgeBases');
  }

  getKnowledgeBases(): void{
    this.getKnowledgeBaseRequest().subscribe(
     {
       next : data => {
           this.knowledgeBases=data;
           store.set('knowledgeBases',data)
       },
       error: error => {
         if(error.status === 401){
           this.notification.warning('Knowledge Base Error','It was impossible to retrieve available Knowledge Bases');
         }
       }
     }
   ) 
   }
 
   getKnowledgeBaseRequest() : Observable<any>{
     const headers = new HttpHeaders()
     .set('Content-Type', 'application/json')
     .set('Accept', '*/*');
     const baseURL = this.keBackend+'/sc';
     return this.http.get<any>(baseURL,{headers})
   }

   updateFocusKnowledgeBase(idx:number){
     store.set('knowledgeBaseinFocus',idx);
     this.indexInFocus=idx;
     this.KnowledgeBaseInFocus.emit(idx);
     window.scroll(0,0);
   }

   selectedKnowledgeBase(index:number){
     if(index == this.indexInFocus){
       return true;
     }
   }
}
