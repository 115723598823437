<div class="d-flex flex-nowrap align-items-start pt-4">
  <div class="kit__utils__avatar kit__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
    <img src="assets/images/avatars/3.jpg" alt="Mary Stanform" />
  </div>
  <div class="flex-grow-1">
    <div class="border-bottom">
      <div class="d-flex flex-wrap mb-2">
        <div class="mr-auto">
          <div class="text-gray-6">
            <span class="text-dark font-weight-bold">Helen maggie</span> posted
          </div>
          <div>Few seconds ago</div>
        </div>
        <div class="nav-item dropdown">
          <a href="javascript:;" class="nav-link dropdown-toggle pt-sm-0" nz-dropdown [nzDropdownMenu]="menu"
            nzTrigger="click" nzPlacement="bottomRight">
            Actions
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item><a href="javascript:;">Action</a></li>
              <li nz-menu-item><a href="javascript:;">Another action</a></li>
              <li nz-menu-item><a href="javascript:;">Something else here</a></li>
              <li nz-menu-divider></li>
              <li nz-menu-item><a href="javascript:;">Separated link</a></li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      <div class="mb-3">
        Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
        <br /> incididunt ut labore et dolore magna elit
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
        <a class="text-blue mr-3" href="javascript: void(0);">
          <i class="fe fe-heart mr-1"></i> 61 Likes
        </a>
        <a class="text-blue mr-3" href="javascript: void(0);">
          <i class="fe fe-message-square mr-1"></i> 2 Comments
        </a>
      </div>
    </div>
    <div class="d-flex flex-nowrap align-items-start pt-4">
      <div class="kit__utils__avatar kit__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
        <img src="assets/images/avatars/3.jpg" alt="Mary Stanform" />
      </div>
      <div class="flex-grow-1">
        <div class="border-bottom">
          <div class="d-flex flex-wrap mb-2">
            <div class="mr-auto">
              <div class="text-gray-6">
                <span class="text-dark font-weight-bold">Helen maggie</span> posted
              </div>
              <div>Few seconds ago</div>
            </div>
            <div class="nav-item dropdown">
              <a href="javascript:;" class="nav-link dropdown-toggle pt-sm-0" nz-dropdown [nzDropdownMenu]="menu"
                nzTrigger="click" nzPlacement="bottomRight">
                Actions
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item><a href="javascript:;">Action</a></li>
                  <li nz-menu-item><a href="javascript:;">Another action</a></li>
                  <li nz-menu-item><a href="javascript:;">Something else here</a></li>
                  <li nz-menu-divider></li>
                  <li nz-menu-item><a href="javascript:;">Separated link</a></li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
          <div class="mb-3">
            Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
            <br /> incididunt ut labore et dolore magna elit
          </div>
          <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
            <a class="text-blue mr-3" href="javascript: void(0);">
              <i class="fe fe-heart mr-1"></i> 61 Likes
            </a>
            <a class="text-blue mr-3" href="javascript: void(0);">
              <i class="fe fe-message-square mr-1"></i> 2 Comments
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-nowrap align-items-start pt-4">
      <div class="kit__utils__avatar kit__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
        <img src="assets/images/avatars/3.jpg" alt="Mary Stanform" />
      </div>
      <div class="flex-grow-1">
        <div class="border-bottom">
          <div class="d-flex flex-wrap mb-2">
            <div class="mr-auto">
              <div class="text-gray-6">
                <span class="text-dark font-weight-bold">Helen maggie</span> posted
              </div>
              <div>Few seconds ago</div>
            </div>
            <div class="nav-item dropdown">
              <a href="javascript:;" class="nav-link dropdown-toggle pt-sm-0" nz-dropdown [nzDropdownMenu]="menu"
                nzTrigger="click" nzPlacement="bottomRight">
                Actions
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item><a href="javascript:;">Action</a></li>
                  <li nz-menu-item><a href="javascript:;">Another action</a></li>
                  <li nz-menu-item><a href="javascript:;">Something else here</a></li>
                  <li nz-menu-divider></li>
                  <li nz-menu-item><a href="javascript:;">Separated link</a></li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
          <div class="mb-3">
            Lorem ipsum dolor sit amit,consectetur eiusmdd tempory
            <br /> incididunt ut labore et dolore magna elit
          </div>
          <div class="d-flex flex-wrap justify-content-start align-items-start mb-3">
            <a class="text-blue mr-3" href="javascript: void(0);">
              <i class="fe fe-heart mr-1"></i> 61 Likes
            </a>
            <a class="text-blue mr-3" href="javascript: void(0);">
              <i class="fe fe-message-square mr-1"></i> 2 Comments
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>