import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-list-21',
  templateUrl: './21.component.html',
})
export class CuiList21Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
