import { Component, OnInit, Input } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd'

declare var require: any
const data: any = require('./data.json')

@Component({
  selector: 'kit-table-docker-container',
  templateUrl: './docker-container-table.component.html',
  styleUrls: ['./docker-container-table.component.scss'],
})
export class DockerContainerTableComponent implements OnInit {
  isAllDisplayDataChecked = false
  isIndeterminate = false
  listOfDisplayData = data
  listOfAllData = data
  mapOfCheckedId = { 1: true, 2: true, 3: true }

  backend : string;
  service:any;


  @Input() dockerImages: any;

  currentPageDataChange($event): void {
    this.listOfDisplayData = $event
    this.refreshStatus()
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData
      .filter(item => item.active)
      .every(item => this.mapOfCheckedId[item.b2bid])
    this.isIndeterminate =
      this.listOfDisplayData
        .filter(item => item.active)
        .some(item => this.mapOfCheckedId[item.b2bid]) && !this.isAllDisplayDataChecked
  }

  checkAll(value): void {
    this.listOfDisplayData
      .filter(item => item.active)
      .forEach(item => (this.mapOfCheckedId[item.b2bid] = value))
    this.refreshStatus()
  }

  constructor(private http: HttpClient,private store: Store<any>,private notification: NzNotificationService) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.backend = state.backend
    })
  }

  startImage(dockerId:number){
    this.startContainerRequest(dockerId).subscribe(
      {
        next: data => {
          this.notification.success("Container Started","The Docker container for the service was started.")
          this.ngOnInit()
        },
        error: error => {
          if(error.status === 401){
            this.notification.warning('Container failed to start','There was an error while attempting to start the container.');
          }
        }
      }
    )
  }

  startContainerRequest(dockerId: number) : Observable<any>{
    const accessToken = store.get('accessToken')
    const headers = new HttpHeaders()
    .set('Authorization', `Bearer ${accessToken}`)
    .set('Content-Type', 'application/json')
    .set('Accept', '*/*')
    ;
    const baseURL = this.backend+'/api/dockers/start_container?dockerId='+dockerId;
    return this.http.get<any>(baseURL,{headers})
  }

  ngOnInit() {
  }

    







  
}
