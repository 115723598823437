import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-1v1',
  templateUrl: './1v1.component.html',
})
export class CuiGeneral1v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
