import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-9',
  templateUrl: './9.component.html',
})
export class CuiGeneral9Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
