<div>
  <!--div class="height-200 d-flex flex-column head style="background: url('assets/images/InterConnectLogo.png')"-->

  <div class="card card-borderless">
    <div class="card-header border-bottom-0">
      <div class="d-flex mb-1">
        <div class="text-dark text-uppercase font-weight-bold mr-auto">Knowledge Bases</div>
        <div class="text-gray-6">KPI</div>
      </div>
      <div class="d-flex mb-2">
        <div class="text-success font-size-24 font-weight-bold mr-auto">10</div>
        <div class="text-gray-4 font-size-24">100</div>
      </div>
      <div class="progress">
        <div class="progress-bar bg-success" style="width: 50%"></div>
      </div>
    </div>
    <div class="card-body pt-1">
    </div>
  </div>
</div>