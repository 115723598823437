<div class="row">
  <div class="col-lg-3">
    <div class="item mb-xl-0 mb-3">
      <span class="icon">
        <i class="fe fe-home"></i>
      </span>
      <div class="desc">
        <span class="title">Block Title</span>
        <p>Waiting for review</p>
      </div>
      <div class="line bg-success"></div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="item mb-xl-0 mb-3">
      <span class="icon">
        <i class="fe fe-command"></i>
      </span>
      <div class="desc">
        <span class="title">Block Title</span>
        <p>Waiting for review</p>
      </div>
      <div class="line bg-primary"></div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="item mb-xl-0 mb-3">
      <span class="icon">
        <i class="fe fe-star"></i>
      </span>
      <div class="desc">
        <span class="title">Block Title</span>
        <p>Waiting for review</p>
      </div>
      <div class="line bg-warning"></div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="item mb-xl-0 mb-3">
      <span class="icon">
        <i class="fe fe-database"></i>
      </span>
      <div class="desc">
        <span class="title">Block Title</span>
        <p>Waiting for review</p>
      </div>
      <div class="line bg-gray-5"></div>
    </div>
  </div>
</div>
