import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-general-13interconnect-knowledgeInteractions',
  templateUrl: './13interconnect-knowledgeInteractions.component.html',
  styleUrls: ['./13interconnect-knowledgeInteractions.component.scss'],
})
export class CuiGeneral13interconnectKnowledgeIntercationsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
