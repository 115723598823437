<div>
  <div class="card card-borderless">
    <div class="card-header border-bottom-0">
      <div>
        <a
        class="favorite"
        [ngClass]="[isFavorite ? 'text-dark' : 'text-gray-3']"
        [routerLink]=""
        (click)="setFavorite()"
      >
        <i class="fe fe-star font-size-21"></i>
      </a>
      </div>
      <div class="d-flex mb-1">
        <div class="text-dark text-uppercase font-weight-bold mr-auto">
          <a [routerLink]="['/kpi/kpi-detail',kpi_id]">
            {{kpi.description}}
          </a>
        </div>
        <div class="text-gray-6"><span class="flag-icon flag-icon-{{kpi.pilot}}"></span> Pilot</div>
      </div>
      <div class="d-flex mb-2" *ngIf=hasData>
        <div class="text-success font-size-24 font-weight-bold mr-auto">{{kpiFirstReading}}</div>
        <div class="text-gray-4 font-size-24">{{kpi.goal}}</div>
      </div>
      <div class="d-flex mb-2" *ngIf=!hasData>
        <div class="text-success font-size-24 font-weight-bold mr-auto">No available data</div>
      </div>
      <div class="progress">
        <div class="progress-bar bg-success" style="width: {{ratio}}%"></div>
      </div>
      <div class="d-flex mb-2">
        <!--<div class="text-dark text-uppercase  mr-auto">{{kpi.provider}}</div>-->
        <div class="image width-100">
          <img class="img-fluid" [src]="kpi.image" [alt]="name" />
        </div>
      </div>
    </div>

    <div class="card-body pt-1">
    </div>
  </div>
</div>