<div class="d-flex flex-wrap align-items-center">
  <div class="mr-auto">
    <p class="text-uppercase text-dark font-weight-bold mb-1">
      Your profit
    </p>
    <p class="text-gray-5 mb-0">
      Average Weekly Profit
    </p>
  </div>
  <p class="text-success font-weight-bold font-size-24 mb-0">
    +29,931
  </p>
</div>
