<div>
  <div class="d-flex flex-wrap mb-3">
    <div class="item">
      <div class="font-size-18 text-dark font-weight-bold">
        +$12,367.36 <span class="text-success font-size-12 align-text-top">+25%</span>
      </div>
      <div class="text-uppercase text-gray-4">Total sales</div>
    </div>
    <div class="item">
      <div class="font-size-18 text-dark font-weight-bold">
        +$5,367.36 <span class="text-danger font-size-12 align-text-top">-76%</span>
      </div>
      <div class="text-uppercase text-gray-4">Avg. Per Day</div>
    </div>
  </div>
  <x-chartist [data]="chartData" [type]="'Bar'" [options]="chartOptions" class="height-300 ct-hidden-points">
  </x-chartist>
</div>