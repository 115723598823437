export const getMenuData: any[] = [
  {
    category: true,
    title: 'Service Store',
  },
  {
    title: 'Home',
    key: 'dashboard',
    icon: 'fe fe-home',
    url: '/dashboard/home',
    // roles: ['admin'], // set user roles with access to this route
  },
  {
    category: true,
    title: 'Services',
  },
  {
    title: 'My Services',
    key: 'MyServiceCatalog',
    url: '/service/my-service-catalog',
    icon: 'fe fe-grid',
  },
  {
    title: 'My Onboardings',
    key: 'MyServiceOnboardings',
    url: '/service/my-onboardings',
    icon: "fe fe-layers"
  },
  {
    title: 'Service Explorer',
    key: 'Catalog',
    url: '/service/service-catalog',
    icon: "fe fe-box"
  },
  //{
  //  title: 'Service Details',
  //  key: 'ServiceDetails',
  //  url: '/service/service-details',
  //},
  {
    category: true,
    title: 'Knowledge center',
  },
  {
    title: 'Knowledge Explorer',
    key: 'Knowledge-Explorer-Component',
    url: '/ke/knowledge-explorer',
    icon: "fe fe-book-open"
  },
  //{
  //  title: 'Knowledge Interactions',
  //  key: 'Knowledge-Interaction-Component',
  //  url: '/ke/ki',
  //},
  //{
  //  category: true,
  //  title: 'Ledger',
  //},
  //{
  //  title: 'InterConnect Ledger',
  //  key: 'dashboardCrypto',
  //  url: '/dashboard/crypto',
  //},
  {
    category: true,
    title: 'KPIs',
  },
  {
    title: 'KPI Explorer',
    key: 'KPI-Explorer-Component',
    url: '/kpi/kpi-explorer',
    icon: "fe fe-activity"
  },
]

