<div>
  <div class="height-300 d-flex flex-column justify-content-end">
    <perfect-scrollbar style="height: 100%">
      <div class="contentWrapper">
        <div class="message">
          <div class="messageContent">
            <div class="text-gray-4 font-size-12 text-uppercase">You, 5 min ago</div>
            <div>Hi! Anyone here? I want to know how I can buy Air UI?</div>
          </div>
          <div class="avatar messageAvatar">
            <img src="assets/images/avatars/avatar-2.png" alt="You" />
          </div>
        </div>
        <div class="message answer">
          <div class="messageContent">
            <div class="text-gray-4 font-size-12 text-uppercase">Mary, 14 sec ago</div>
            <div>Please call us + 100 295 000</div>
          </div>
          <div class="avatar messageAvatar">
            <img src="assets/images/avatars/2.jpg" alt="Mary Stanform" />
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="pt-2 pb-2">Mary is typing...</div>
  <form action>
    <div class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Send message..." aria-label="Recipient's username"
        aria-describedby="button-addon2" />
      <div class="input-group-append">
        <button class="btn btn-primary" type="button">
          <i class="fe fe-send align-middle"></i>
        </button>
      </div>
    </div>
  </form>
</div>
