<div>
  <div class="text-dark font-size-18 font-weight-bold mb-1">Year Profit</div>
  <div class="text-gray-6 mb-2">Revenue by location and date</div>
  <x-chartist
    [data]="chartData"
    [type]="'Bar'"
    [options]="chartOptions"
    class="height-200 chart"
  >
  </x-chartist>
</div>
