<div class="card overflow-hidden">
  <div  class=status>
    <h2 *ngIf=isRunning class="badge-example">RUNNING</h2>
  </div>
  <div  class=status>
    <h2 *ngIf=!isRunning class="badge-example">STOPPED</h2>
  </div>
  <div class="card-body">
      <i class="fe fe-heart font-size-21"></i>
    <div class="image border-bottom height-150 mb-3">
      <img class="img-fluid" [src]="image" [alt]="name" />
    </div>
    <div>
        {{name}}
    </div>
    <div class="font-size-20 font-weight-bold mb-2">
      {{platform}}{{'   '}}{{ontology}}{{' '}}{{ontologyVersion}}{{'   '}}{{type}}
    </div>
    <div class="font-size-20 font-weight-bold mb-2">
      {{containerID}}
    </div>
    <button type="button" *ngIf="!containerIsStopped()"class="btn btn-outline-warning mr-2 mb-2" (click)="stopContainer(containerID)">Stop Container</button>
    <button type="button" *ngIf="containerIsStopped()" class="btn btn-outline-warning mr-2 mb-2" (click)="removeContainer(containerID)">Remove Container</button>
    <button type="button" *ngIf="containerIsStopped()" class="btn btn-outline-success mr-2 mb-2" (click)="startContainer(containerID)">Start Container</button>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>