<div class="footer">
  <div class="footerInner">
    <a href="https://knowledge-engine.eu/" target="_blank" rel="noopener noreferrer" class="logo">
      Knowledge Engine
      <span></span>
    </a>
    <br />
    <p class="mb-0">
      Copyright © 2021
      <a href="" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </p>
  </div>
</div>