import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-chart-12v1',
  templateUrl: './12v1.component.html',
})
export class CuiChart12v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
